$base-color-disabled: gray;

.mainAtalhos {
  transition: all 0.15s ease-in-out !important;
  padding: 30px 10px !important;
  background: linear-gradient(90deg, #279cf6 2%, #fff 2%, #fff 100%) !important;
  box-shadow: 0px 0px 56px rgba(0, 0, 0, 0.1) !important;

  &:hover {
    background: linear-gradient(
      90deg,
      #279cf6 2%,
      #f1faff 2%,
      #f1faff 100%
    ) !important;
    margin-left: 1%;
  }

  &-icon-main {
    font-size: 26px !important;
  }

  &-text-main {
    padding-left: 10px !important;
    font-size: clamp(12px, 102%, 800px) !important;
    font-weight: 600 !important;
    text-transform: capitalize;
  }
}

@media (min-width: 550px) and (max-width: 750px) {
  .mainAtalhos {
    &-icon-main {
      display: none;
    }
  }
}

@media (max-width: 550px) {
  .atalhos-main-view {
    display: none;
  }
}

.mainAtalhosDisabled {
  background: linear-gradient(
    90deg,
    #279cf6 2%,
    #f9f9f9 2%,
    #f9f9f9 100%
  ) !important;

  &-icon,
  &-text {
    color: $base-color-disabled !important;
  }
}

.versao {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px;
  font-size: 10px;
}

.img-logo-dashboard {
  width: 25vw;
  opacity: 11%;
}
