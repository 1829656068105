.actionBar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 16px;
  padding: 0 28px;
}
.card {
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 16px;
}
.addVariantButton {
  margin-left: 1rem !important;
}

.addVariantValueButton {
  margin-top: 20px !important;
}
.paper {
  height: auto;
  margin-top: 16px;
  border-color: #bbb;
  padding: 0 2px 10px 15px;
}
.chip {
  margin-top: 9.5px !important;
  margin-right: 4px !important;
}
.placeholderGrade {
  color: #999 !important;
  margin-top: 16px !important;
}
.addIcon {
  color: white !important;
  height: 20px;
  width: 20px;
  margin-right: 2px;
}
.adicionarButton {
  margin: 1.6px;
  height: 42px;
  padding-right: 24px;
}
.submitButton {
  margin: 1.6px;
  height: 42px;
  padding-right: 24px;
  background-color: green;
  color: white;
}
.cancelButton {
  margin: 1.6px;
  height: 42px;
  padding-right: 24px;
  color: white;
}
.undoButton {
  margin: 1.6px;
  height: 42px;
}
.doneIcon {
  font-size: 20px;
  margin-right: 4px;
}
.replayIcon {
  font-size: 20px;
}
