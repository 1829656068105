.nfePagCss {
  margin-top: 16px;
}

.menuItem:hover {
  background-color: #eaeaea !important;
}

.menuItem:focus {
  background-color: #dddddd !important;
}
