.gridContainer {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.inputGridTop {
  margin-top: 16px;
}

.inputGridBottom {
  margin-bottom: 50px;
}

.logoImageInput {
  display: none;
}

.mediaContainer {
  position: relative;
  margin: 0 0 0 32px;
  padding: 2px;
  border: 1px solid #eee;
  border-radius: 4px;
}

.editImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease-in-out;
  background: rgba(32, 33, 36, 0.6);
  top: 0;
  bottom: 0;
  height: 200px;
  left: 2px;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.editImageIcon {
  font-size: 32px;
  color: #eee !important;
  opacity: 0.8;
}

.tabPanel {
  margin-top: 24px;
}
