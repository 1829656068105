.actionBar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 16px !important;
  padding: 0 28px;
}

.card {
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 16px !important;
}

.diasSemanaContainer {
  margin-top: 16px !important;
}

.diasSemana {
  margin-top: 10px !important;
}

.aplicarDescontoEmContainer {
  margin-top: 16px !important;
}

.descontoTabelaInput {
  margin-left: 12px !important;
}

.cardActions {
  margin-bottom: 24px !important;
}

.typography {
  margin-top: 10px !important;
}

.tipoCondicaoTipography {
  margin-top: 32px !important;
}

.aplicarDescontoEmTypography {
  margin-top: 32px !important;
}

.tipoCondicaoRadioButton {
  margin-top: 22px !important;
}

.aplicarDescontoEmRadioButton {
  margin-top: 22px !important;
}

.submitButton {
  margin: 0.4rem;
  height: 42px;
  padding-right: 24px;
  background-color: green;
  color: white;
}

.cancelButton {
  margin: 0.4rem;
  height: 42px;
  padding-right: 24px;
  background-color: grey;
  color: white;
}

.clearIcon {
  font-size: 20px;
  margin-right: 4px;
}

.undoButton {
  margin: 0.4rem;
  height: 42px;
}

.doneIcon {
  font-size: 20px;
  margin-right: 4px;
}

.replayIcon {
  font-size: 20px;
}

.lightIcon {
  color: white !important;
}

.submitDesconto {
  margin: 0.4rem;
  height: 42px;
  padding-right: 24px;
}

.dataGrid {
  & .colCell {
    flex: 1;
    min-width: 1px !important;
    max-width: none !important;
  }

  .viewport {
    & .rendering-zone {
      width: initial !important;
      max-width: initial !important;
    }
  }

  .row {
    width: 100% !important;
  }

  .colCellWrapper {
    display: flex;
  }

  .cell {
    flex: 1;
    min-width: 1px !important;
    max-width: none !important;

    &:last-of-type {
      min-width: 0 !important;
      flex: 0;
    }
  }
}
