.ss-principal {
  font-weight: 600;
  color: #004780;
  font-size: 3.4vw;
  text-align: end;
}
.ss-secundario {
  line-height: 149.9%;
  color: #fff;
  font-size: 1.6vw;
  text-align: end;
}

.txt-color-purple {
  color: #494c62;
  font-weight: 800 !important;
  font-size: 3rem;
  margin-bottom: 0.6rem;
}

.button-disabled {
  color: #fff !important;
  background: conic-gradient(
    from -42.91deg at 26.46% 50%,
    #666768 0deg,
    #c9caca 360deg
  );
  border-radius: 15px !important;
  padding: 12px !important;
}
.bloco-logo {
  position: absolute;
  top: 0;
  left: 0;
  margin: 2rem !important;
}

.logo {
  display: flex;
  justify-content: center;
  filter: brightness(100);
  padding-bottom: 1rem;
  margin-bottom: 0.2rem;
  text-align: center;
}

.bg-login {
  width: 100%;
  height: 100vh;
  background: rgb(33, 150, 243);
  background: conic-gradient(
    from 26.63deg at 11.1% 27.4%,
    #2196f3 0deg,
    #004780 360deg
  );
}

.login {
  position: absolute;
  right: 0;
  left: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: white;
  border-radius: 2rem 0 0 2rem;
  width: clamp(550px, 40vw, 778px) !important;
}

@media (max-width: 1024px) {
  .info-tab {
    display: none !important;
  }
  .bg-login {
    background: white;
  }
  .login {
    width: 100% !important;
  }
  .bloco-logo {
    z-index: 1000;
    display: flex;
    align-items: center;
    column-gap: 7px;
    left: auto;
    filter: invert(53%) sepia(24%) saturate(1615%) hue-rotate(164deg)
      brightness(96%) contrast(98%);
  }
}
