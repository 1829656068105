#conteudo_nfe {
  margin: auto;
  padding: 0;
  font-size: 9px;
}

.danfe_linha_tracejada {
  width: 100%;
  border-bottom: #000 0.1px dashed;
  margin: 10px 0 10px 0;
}

.danfe_tabelas {
  border-collapse: collapse;
  width: 100%;
  height: 50%;
  margin: 0;
  padding: 0;
  line-height: 12px;
}

.danfe_celula_bordas {
  border-collapse: collapse;
  border: 0.1px solid black;
  vertical-align: top;
  padding: 2px;
}

.danfe_celula_borda_topo {
  border-collapse: collapse;
  border-top: 0.1px solid black;
  vertical-align: top;
  padding: 2px;
}

.danfe_celula_borda_canto_esquerdo {
  width: 122px;
  border-collapse: collapse;
  border-left: 0.1px solid black;
  border-top: 0.1px solid black;
  vertical-align: top;
  padding: 2px;
}

.danfe_celula_borda_top_left_bot {
  border-collapse: collapse;
  border-left: 0.1px solid black;
  border-top: 0.1px solid black;
  border-bottom: 0.1px solid black;
  vertical-align: top;
  padding: 2px;
}

.barra_chave {
  width: 25%;
}

.danfe_celula_titulo {
  margin: 0;
  font-size: 6pt;
  padding: 0 2px 0px 2px;
}

.danfe_celula_valor {
  display: inline-block;
  margin: 0;
  font-size: 7pt;
  vertical-align: bottom;
}

.valor_duplicata {
  font-size: 4pt;
}

.valor_negrito {
  font-size: 8pt;
  font-weight: bold;
}

.danfe_canhoto_bordas {
  font-size: 6pt;
  border: 0.1px solid #000;
  margin: 0px;
  padding: 0;
  margin: 0 1px 0 1px;
  min-height: 30px;
}

.danfe_canhoto_texto {
  font-size: 5pt;
  margin: 0;
  font-weight: normal;
  padding: 0 2px 1px 2px;
}

.danfe_cabecalho_danfe {
  font-size: 11px;
  font-weight: bold;
  margin: 0;
  text-align: center;
}

.danfe_cabecalho_danfe_texto {
  font-size: 5pt;
  padding: 0;
  margin: 0 1px 0 1px;
  text-align: center;
}

.danfe_cabecalho_numero {
  font-size: 9px;
  font-weight: bold;
  margin: 0;
  text-align: center;
}

.danfe_cabecalho_entrada_saida {
  font-size: 6pt;
  margin-right: 5px;
  padding-right: 5px;
}

.danfe_cabecalho_entrada_saida_quadrado {
  font-size: 9pt;
  border: 0.1px solid #000000;
  padding: 0;
  margin: 0;
  width: 100px;
  text-align: center;
  float: none;
  min-width: 30px;
}

.danfe_titulo_externo {
  font-size: 7pt;
  margin: 0.5em 0 0.5em 0;
  font-weight: bold;
  text-transform: uppercase;
}

.danfe_item {
  border: 0.1px black solid;
  border-top: none;
  border-bottom: dotted 1pt #dedede;
  text-align: left;
}


.danfe_item td {
  word-wrap: break-word;
  overflow-wrap: break-word;
  border-left: solid black 0.1px;
  padding: 4px;
  text-align: center;
  font-size: 7px;
  height: 27px;
}

.danfe_item_ultimo {
  border: 0.1px black solid;
  border-top: none;
  margin: 0px;
  padding: 0;
  font-size: 1px;
}

.danfe_item_cabecalho {
  word-wrap: break-word;
  overflow-wrap: break-word;
  border: 0.1px solid #000;
  padding: 3px;
  text-align: center;
  font-size: 5pt;
  text-transform: uppercase;
}

.danfe_item_cabecalho_tabela {
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 0.1px solid #000;
}

.danfe_nome_empresa {
  font-size: 8pt;
  font-weight: bold;
  text-align: center;
  padding-top: 10px;
}

.danfe_nome_empresa_esquerdo {
  @extend .danfe_nome_empresa;
  padding-top: 15%;
  text-align: start;
}

.danfe_endereco_empresa {
  font-size: 7px;
  text-align: center;
}

.danfe_logo_empresa {
  width: 100%;
  height: 100%;
  max-height: 65px;
  max-width: 65px;
  float: left;
  display: block;
  margin: 23% 19%;
}

.danfe_logo_empresa_ret {
  margin-top: 6px;
  height: 35px;
  width: 300px;
  display: block;
  padding-left: 17px;
}

.div_endereco_ret {
  padding-left: 100px;
  font-size: 9px;
}

#danfe_cabecalho {
  height: 150px;
}

#cabecalho {
  height: 150px;
  page-break-before: always;
}

#danfe_rodape {
  margin-top: 1em;
}

#rodape {
  margin-top: 1em;
  position: absolute;
  bottom: 0;
  width: 100%;
}

#danfe_cancelada {
  opacity: 0.4;
  position: absolute;
  top: 25%;
  left: 10%;
  font-size: 12vw;
  color: #bf3030;
}

#danfe_sem_valor {
  opacity: 0.4;
  position: absolute;
  top: 55%;
  left: 10%;
  font-size: 12vw;
  color: #bf3030;
}

#rodape_legenda {
  font-size: 6px;
  padding-top: 5px;
  text-align: end;
}
