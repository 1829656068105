.aplicarDescontoEmRadioButton {
  margin-top: 22px !important;
}

.aplicarDescontoEmTypography {
  margin-top: 32px !important;
}

.card {
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 16px;
}

.descontoTabelaInput {
  margin-left: 12px;
}

.tipoTabela {
  margin-top: 10px !important;
}

.tipoTabelaContainer {
  margin-top: 16px !important;
}

.typography {
  margin-top: 10px !important;
}
