.textRolagem {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 80px;
  max-height: 120px;
  min-height: 80px;
  width: 100%;
  padding-inline: 18.5px;
}

.labelTextareaAutosize {
  color: #8d8c8c;
}
