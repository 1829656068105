#relatorio_caixa_diario * {
  margin: 0;
  font-family: sans-serif;
  width: 100%;
  /* //height: 100%; */
}

#relatorio_caixa_diario {
  padding: 2px;
  margin: 0;
}

#relatorio_caixa_diario h6 {
  margin: 1.5px;
  font-size: 9px;
  text-transform: uppercase;
}

#relatorio_caixa_diario h5 {
  margin: 1.5px;
  font-size: 11px;
  text-transform: uppercase;
}

.listagemValores {
  display: flex;
  justify-content: space-between;
  text-align: left;
  font-size: 10px;
  font-weight: 700;
}

.listaValor {
  text-align: right;
  padding-left: 50px;
}

.border-hr {
  border-width: 0 0 0.1px 0;
  border-color: black;
  border-style: solid;
}
