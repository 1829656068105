#totalReceita {
  background: linear-gradient(
    90deg,
    rgba(79, 187, 76, 1) 5%,
    rgba(255, 255, 255, 1) 5%,
    rgba(255, 255, 255, 1) 100%
  );
}

#totalDespesa {
  background: linear-gradient(
    90deg,
    rgba(220, 53, 79, 1) 5%,
    rgba(255, 255, 255, 1) 5%,
    rgba(255, 255, 255, 1) 100%
  );
}

#totalSaldo {
  background: linear-gradient(
    90deg,
    rgba(33, 150, 243, 1) 5%,
    rgba(255, 255, 255, 1) 5%,
    rgba(255, 255, 255, 1) 100%
  );
}
