.addRemoveQtButton {
  height: 30px;
  width: 30px;
  border-radius: "50%";
  border: "1px solid #ccc";
}

.addRemoveQtIcon {
  cursor: "pointer";
  font-size: 18px;
}

.qtValueSpan {
  margin-left: 28px;
  margin-right: 28px;
}

.comissaoIcon {
  margin-left: 16px;
  cursor: "pointer";
  font-size: 18px;
}
