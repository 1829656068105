.h-100-vh {
  height: 100vh;
}
.title-color {
  color: #828282;
}

.zindex-modal {
  z-index: 1000;
}

.bg-cadastro-empresa {
  background: rgb(33, 150, 243);
  /* background: conic-gradient(
    from 26.63deg at 11.1% 27.4%,
    #2196f3 0deg,
    #004780 360deg
  ); */
}

.line {
  width: 120vw;
  z-index: 0;
  top: 344px;
  right: -116px;
}

.line-elipse {
  width: 20vw;
  z-index: 0;
  top: -128px;
  right: -116px;
}
