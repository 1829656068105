.orcamento-print-fs-07 {
  font-size: 0.7rem;
}

.orcamento-print-fs-11 {
  font-size: 1.1rem;
}

.text-right {
  text-align: end;
}

.orcamento-line-assinatura {
  width: 75%;
  border-top: 1px solid #666;
  margin-top: 24px;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
}

.flex-print-container {
  display: flex;
  flex-wrap: wrap;
  gap: 3em;
  align-items: center;
  margin: 2em 0;
  padding: 0;
}

.container-item-50 {
  flex: 1 1 50%;
  display: flex;
  justify-content: start;
  box-sizing: border-box;
}

.container-item-40 {
  flex: 1 1 40%;
  display: flex;
  justify-content: start;
  box-sizing: border-box;
}
