.shadow-card-light {
  box-shadow: inset 22px 22px 56px #edeef0, inset -22px -22px 56px #ffffff;
  border-radius: 15px !important;
  padding: 20px 30px;
  margin: 0 0 30px 0 !important;
  cursor: pointer;
  &:hover {
    box-shadow: inset 22px 22px 56px #e5e5e5, inset -22px -22px 56px #ffffff;
  }
}
