.edit-image-icon {
  font-size: 32;
  color: #eee;
  opacity: 0.8;
}

.edit-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease-in-out;
  background: rgba(32, 33, 36, 0.4);
  top: 6px;
  bottom: 0;
  height: 200px;
  left: 6px;
  position: absolute;
  right: 6px;
  border-radius: 2px;
  cursor: pointer;
}

.grid-container {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.logo-image-input {
  display: none;
}

.media {
  width: 200px;
  height: 200px;
}

.media-container {
  position: relative;
  align-self: center;
  padding: 7px;
  max-height: 216px;
  border: 1px solid #eee;
  border-radius: 4px;
  margin: 0 16px 0 32px;
}

.text-rolagem {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  height: 80px;
  max-height: 120px;
  min-height: 80px;
  width: 100%;
  padding: 16px;
}
