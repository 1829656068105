.footer-legenda {
  font-size: 11px;
  font-weight: 400;
  text-align: left;

  &-bold {
    background-color: #e5efff !important;
    font-size: 12px;
    font-weight: bolder;
    text-align: left;
  }
}

.footer-valor {
  font-size: 11px;
  font-weight: 400;
  text-align: right;

  &-bold {
    background-color: #e5efff !important;
    font-size: 12px;
    font-weight: bolder;
    text-align: right;
  }
}

.footer-relatorios {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
  background-color: #e5efff !important;
}

.background-azul {
  background-color: #e5efff !important;
}

.footer-relatorios td {
  padding: 8px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.footer-relatorios th {
  text-align: center;
  font-weight: 700 !important;
  font-size: 12px;
}
