.modelo-paper {
  margin-top: 16px;
  margin-bottom: 16px;
}
.modelo-div-title {
  padding: 8px;
}
.modelo-span {
  font-size: 13px;
  line-height: 1.8px;
}

.modelo-actions {
  overflow: auto;
  position: sticky;
  bottom: 0;
}
