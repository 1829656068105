#table-relatorio {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
  }
  
  #table-relatorio th {
    font-weight: 600;
  }
  
  #table-relatorio b {
    font-size: 12px;
  }
  
  #table-relatorio th,
  #table-relatorio td {
    padding: 8px;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  #table-relatorio th:last-child,
  #table-relatorio td:last-child {
    text-align: end;
    width: 10%;
  }
  
  .cell-content {
    text-align: start;
    max-width: 100%;
  }
  
  .cell-content-end {
    text-align: end;
    max-width: 100%;
  }
  
  .report-container {
    font-size: 11px;
    width: 250mm;
    height: 300mm;
    margin: 0 auto;
    margin-top: 20mm;
    box-shadow: 0 0 2em gray;
    padding: 2em;
  }
  
  .print-container {
    font-size: 10px;
    margin: 0 auto;
    padding: 2em;
    page-break-after: always;
  }
  