.topBarInicio {
  display: flex;
  justify-content: center;
  box-shadow: none !important;
  position: fixed;
  border-bottom: 0.25em solid #cac8c8;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.landing-page {
  background-color: white !important;
}

.landing-page header {
  min-height: 80px;
  display: flex;
  overflow: auto;
  position: sticky;
  bottom: 0;
}

.data-top {
  border-radius: 0.7em !important;
  padding: 0 1em;
  border-width: 0.15em;
}

.landing-page header .links {
  display: flex;
  align-items: center;
}

.content {
  background-color: white;
  z-index: 0;
}

.info-inicio-box {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.7em;
  background-color: #2196f3;
  margin: 1em;
  padding: 2em;
  height: 95%;
  max-height: 95%;
}

.landing-image1 {
  width: 85%;
  margin-top: 1em;
  margin-left: 3em;
}

.caracteristica {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #b1dfff;
  border-radius: 0.7em;
}

.titulo-box {
  color: white !important;
  font-weight: 800;
  text-align: flex-start;
}

.paragrafo-box {
  color: #0c2f6e;
  font-size: 1.5vh;
  text-align: center;
  font-weight: 2em;
  margin: 1em;
}

.subtitulo-box {
  color: #15252b;
}

.botao-box {
  color: #2196f3 !important;
  background-color: white !important;
  border-radius: 0.7em !important;
  box-shadow: none !important;
  margin-top: 1em !important;
}

.estilo-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5em;
}

.estilo-paper {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f7 !important;
  border-radius: 0.7em;
  height: 100%;
  width: 100%;
  box-shadow: none !important;
}

@media (max-width: 767px) {
  .landing-page .content .info {
    text-align: center;
    margin-bottom: 15px;
  }

  .paragrafo-box {
    text-align: center;
  }

  .titulo-box {
    text-align: center;
  }
}

.landing-page .content .info button {
  padding: 12px 30px;
  margin-top: 30px;
}

.landing-page .content .image img {
  max-width: 100%;
}
