#table-relatorio {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

#table-relatorio th {
  font-weight: 600;
}

#table-relatorio b {
  font-size: 12px;
}

#table-relatorio th,
#table-relatorio td {
  padding: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
}

.report-container {
  font-size: 11px;
  width: 250mm;
  height: 300mm;
  margin: 0 auto;
  margin-top: 20mm;
  box-shadow: 0 0 2em gray;
  padding: 2em;
}

.print-container {
  font-size: 10px;
  margin: 0 auto;
  padding: 2em;
  page-break-after: always;
}